export const MODAL_NAME = {
  AGGREGATE_MANUAL_MODAL: 'AGGREGATE_MANUAL_MODAL',
  MAINTENANCE_MODAL: 'MAINTENANCE_MODAL',
  TUTORIAL: 'TUTORIAL_MODAL',
  DELETED_STOCK_USER_UNUSED: 'DELETED_STOCK_USER_UNUSED'
};

export const aggTypeConstant = {
  bank: 'bank',
  card: 'card',
  emoney: 'emoney',
  point: 'point',
  ec: 'ec',
  stock: 'stock',
  BANK: 'BANK',
  CARD: 'CARD',
  AU: 'AU',
  EMONEY: 'EMONEY',
  POINT: 'POINT',
  EASY_PAY: 'EASY_PAY',
  EC: 'EC',
  STOCK: 'STOCK'
};

export const pathConstant = {
  asset: 'asset',
  history: 'history',
  category: 'category',
  input: 'input',
  accountsAccountPath: '/accounts/account/',
  modalHisByCategoryPath: '/history/category/transaction/',
  hisByCategoryPath: '/history/category/',
  topPath: '/asset/',
  assetPath: '/asset/',
  assetDetailPath: '/asset/detail/',
  assetAuPayPath: '/asset/au_pay/',
  historyAll: '/history/all/',
  inputTransaction: '/input/',
  transactionDetail: '/asset/transaction/',
  historyTransactionDetail: '/history/transaction/',
  qaPath: '/faq/',
  lifePlan: '/household_support/',
  report: '/report/',
  setting: '/accounts/',
  menu: '/menu/',
  reportSetting: '/report/setting/',
  assetAccount: '/asset/account/',
  cateHistoryAll: '/history/all/',
  historyCategory: '/history/category/',
  booking: '/booking/',
  lifePlanSurvey: '/life_plan/survey/',
  columns: '/columns/',
  lifePlanAfterChoose: '/life_plan/',
  termOfUse: '/asset/term_of_use',
  preAuth: '/pre_auth'
};

export const AccountType = {
  au_prepaid: 'au-prepaid',
  au_credit: 'au-credit',
  au_jbank: 'au-jbank',
  mf_point: 'mf-point',
  emoney: 'emoney',
  au_ponta: 'au_ponta',
  au_easy_pay: 'au_easy_pay',
  au_market: 'au_market',
  au_ponta_market: 'au_ponta_market',
  au_stock: 'au_stock',
  au_point_unyo: 'au_point_unyo',
  au_kantan_kessai: 'au_kantan_kessai'
};

export const SERVICE_TYPES = [
  { type: aggTypeConstant.POINT, alias: 'ポイント', linkText: '' },
  { type: aggTypeConstant.BANK, alias: '銀行口座', linkText: 'その他の銀行' },
  { type: aggTypeConstant.CARD, alias: 'クレジットカード', linkText: 'その他のクレジットカード' },
  { type: aggTypeConstant.EMONEY, alias: '電子マネー', linkText: 'その他の電子マネー' },
  { type: aggTypeConstant.EC, alias: 'EC', linkText: 'その他のEC' }
];

export const defaultCrawlErrorMessage = '金融機関の都合で連携できません\n時間をおいて再連携してください';
export const defaultKeepDataErrMsg = '金融機関の都合で取得できません\n時間をおいて再取得してください';
export const specialAccErrMsg = '連携に失敗しており再連携が必要です\n※都度再連携が必要な場合があります';
export const maintenanceErrMsg = '情報が取得できませんでした\n時間をおいて再度お試しください。';

export const localStorageKeys = {
  CURRENT_ACCOUNTS: 'CURRENT_ACCOUNTS'
};

export enum TransactionType {
  'INCOME' = 'INCOME',
  'EXPENSE' = 'EXPENSE'
}

export enum EventName {
  TAB_APP_RELOAD = 'TAB_APP_RELOAD',
  GET_INITIAL_DATA_ASSET_TOP = 'GET_INITIAL_DATA_ASSET_TOP'
}

export enum TYPE_FETCH_CHART {
  PREV = 'PREV',
  NEW = 'NEW',
  NEXT = 'NEXT',
  DONE = 'DONE'
}

export enum PATH_ROUTE {
  // TODO: NEED UPDATE PATH_ROUTE for new ver
  'top' = '/asset/',
  'history' = 'history',
  'balance' = 'asset',
  'balance_detail' = '/asset/detail/',
  'recommend' = 'recommend',
  'household_support' = 'household_support',
  'settings' = 'settings',
  'faq' = 'faq',
  'none' = 'none',
  'column' = 'column',
  'booking' = 'booking',
  'life_plan' = 'life_plan',
  'life_style' = 'life_style',
  'transaction' = 'transaction',
  'category' = 'category',
  'input' = 'input',
  'report' = '/report/',
  'menu' = '/menu/',
  'historyAll' = '/history/all/'
}

export const REPORT_TYPE = {
  week: 'week',
  month: 'month'
};
export const WEEK_NUMBERS: {[key: number]: Array<string>} = {
  0: ['5'],
  1: ['5'],
  2: ['4', '5'],
  3: ['3', '4', '5'],
  4: ['2', '3', '4', '5'],
  5: ['1', '2', '3', '4', '5']
};
// incase month is February
export const WEEK_NUMBERS_IN_FEBRUARY: {[key: number]: Array<string>} = {
  0: ['4'],
  1: ['4'],
  2: ['3', '4'],
  3: ['2', '3', '4'],
  4: ['1', '2', '3', '4'],
  5: ['1', '2', '3', '4', '5']
};

export enum MAINTENANCE_STATUS {
  NO_MAINTENANCE = 0,
  SWALLOW_MAINTENANCE = 1,
  BOTH_MAINTENANCE = 3,
}

export const TRACKING_ACC = [
  'au PAY',
  'au PAY カード',
  'auじぶん銀行',
  'Ponta ポイント',
  'Ponta ポイント（au PAY マーケット限定）',
  'Ponta ポイント (au限定)',
  'au PAY マーケット',
  'auかんたん決済(金額タップ)',
  'auかんたん決済'
];

export const TRACKING_STATUS = [
  '新規発行する',
  '口座開設する',
  '利用開始する',
  '新規開設する(カブコム証券)',
  '詳細はこちら（ポイント投資）',
  '詳細はこちら'
];
export const TRACKING_TEXT = [
  '連携はこちら',
  '連携はこちら(カブコム証券)',
  '詳細はこちら（ポイント投資）'
];

export const MY_NUMBER_TYPE_LIST = [
  {
    name: 'show_au_pay_my_number',
    label: 'マイナポイントの表示'
  },
  {
    name: 'show_au_pay_insurance',
    label: '健康保険証の表示'
  },
  {
    name: 'show_jbank_receiving',
    label: '公金受取口座の表示'
  }
];

export const TRACKING_KARTE_ACC_TYPE = [
  AccountType.au_credit,
  AccountType.au_jbank,
  AccountType.au_kantan_kessai,
  AccountType.au_prepaid,
  AccountType.au_market,
  AccountType.au_stock
];

export enum MY_NUMBER_TYPE_NAME {
  show_au_pay_my_number = 'show_au_pay_my_number',
  show_au_pay_insurance = 'show_au_pay_insurance',
  show_jbank_receiving = 'show_jbank_receiving'
}

export const TRACKING_DATA = {
  // TODO: remove or update after June
  // [AccountType.au_credit]: {
  //   action: '新規入会&ご利用で合計最大10,000Pontaポイントもらえる！'
  // },
  [AccountType.au_jbank]: {
    action: 'auじぶん銀行ならau PAY残高へのオートチャージが可能です'
  },
  [AccountType.au_stock]: {
    action: 'お持ちのPontaポイントで手軽に投資信託が買える'
  },
  [AccountType.au_market]: {
    action: 'ポイント交換所で1.5倍に増量し、おトクにショッピング'
  }
};

export const KANTAN_USER_TYPE = {
  au_kantan_light: 'au_kantan_light',
  au_kantan_heavy: 'au_kantan_heavy',
  au_kantan_used: 'au_kantan_used',
  au_kantan_none: 'au_kantan_none'
};
export const CAMPAIGN_TEXT_KARTE_TRANSFER = {
  // TODO: remove or update after June
  // [AccountType.au_credit]: '新規入会&ご利用で合計最大10,000Pontaポイントもらえる！',
  [AccountType.au_jbank]: 'auじぶん銀行ならau PAY残高へのオートチャージが可能です',
  [AccountType.au_market]: 'ポイント交換所で1.5倍に増量し、おトクにショッピング',
  [AccountType.au_stock]: 'お持ちのPontaポイントで手軽に投資信託が買える'
};

export const FINANCIAL_BUNDLE = {
  NOT_APPLIED: 0,
  APPLIED: 1,
  SPECIAL_APPLIED: 2,
  UNKNOWN: -1
};

export const BUNDLE_NOTICE_TEXT = {
  AUPAY_LINKED: 'auマネ活プラン適用中',
  AUPAY_NOTLINKED: 'auマネ活プラン未適用'
};

export const BUNDLE_CONTACT_MSG = {
  UNKNOWN: 'auマネ活プランについては',
  APPLIED: 'auマネ活プランの利用状況は',
  GA_ACTION: 'au PAY枠下部_（auマネ活プラン未連携）auマネ活プランについてはこちら'
};

export const BUNDLE_ELEMENT = {
  LINKED: 'account-linked',
  UNLINKED: 'account-unlinked'
};

export const BUNDLE_GA_MSG = {
  AUPAY_APPLIED: 'au PAY枠下部_（auマネ活プラン連携済）auマネ活プランの利用状況はこちら',
  AUPAY_UNKOWN: 'au PAY枠下部_（auマネ活プラン連携済）auマネ活プランについてはこちら',
  AUPAY_NOTAPPLIED: 'au PAY枠下部_（auマネ活プラン連携済）auマネ活プランなら指定の通信料引き落とし設定でau PAY 残高に月々200円還元！',
  JBANK_UNLINKED: 'auじぶん銀行枠下部_（auマネ活プラン連携済）auマネ活プランならauじぶん銀行の口座開設でau PAY 残高に月々300円還元！',
  JBANK_NOTAPPLIED: 'au PAY枠下部_（auマネ活プラン未連携）auじぶん銀行口座をお持ちならauマネ活プラン加入と条件達成でau PAY 残高に月々最大800円還元！',
  JBANK_NOTAPPLIED_2: 'au PAY枠下部_（auマネ活プラン未連携）au PAY カードをお持ちならauマネ活プラン加入と条件達成でau PAY 残高に月々最大800円還元！',
  STOCK: 'auカブコム証券枠下部_（auマネ活プラン連携済）auマネ活プランならau PAY ゴールドカードのクレカ投信積立特典で最大3%Pontaポイント還元！',
  PAYCARD: 'au PAY カード枠下部_（auマネ活プラン連携済）auマネ活プランならau PAY カード入会でau PAY 残高に月々300円還元！',
  JBANK: 'au PAY枠下部_（auマネ活プラン未連携）auマネ活プランなら条件達成でau PAY 残高に月々最大800円還元！'
};

export const BUNDLE_CAMPAIGN_MSG = {
  JBANK_NOTAPPLIED: 'auマネ活プランならau PAY ゴールドカードの<br/>クレカ投信積立特典で最大3%Pontaポイント還元',
  JBANK_NOTAPPLIED_2: 'au PAY カードをお持ちならauマネ活プラン加入と<br/>条件達成でau PAY 残高に月々最大800円還元！',
  AUPAY_NOTAPPLIED: 'auマネ活プランなら指定の通信料引き落とし設定で<br/>au PAY 残高に月々200円還元！',
  AUPAY_APPLIED: 'auマネ活プランなら条件達成で <br/>au PAY 残高に月々最大800円還元！',
  JBANK_UNLINKED: 'auマネ活プランならauじぶん銀行の口座保有と<br/>au ID登録でau PAY 残高に月々300円還元！',
  JBANK_APPLIED: 'auじぶん銀行口座をお持ちならauマネ活プラン加入と<br/> 条件達成でau PAY 残高に月々最大800円還元！',
  PAYCARD_UNLINKED: 'auマネ活プランならau PAY カード入会で <br/> au PAY 残高に月々300円還元！'
};

export const BUNDLE_DETAIL = {
  TEXT_MSG_APPLIED: 'auマネ活プランの特典適用状況は',
  TEXT_MSG_NOTAPPLIED: 'auマネ活プラン加入と条件達成で<br />au PAY 残高に月々800円還元！詳細は',
  AUPAY_GA_CATE: '【visible】au PAY履歴',
  JBANK_GA_CATE: '【visible】auじぶん銀行履歴',
  PAYCARD_GA_CATE: '【visible】au PAY カード履歴',
  AUPAY_GA_MSG: '（auマネ活プラン未連携）auマネ活プランなら条件達成でau PAY 残高に月々最大800円還元！詳細はこちら',
  PAYCARD_GA_MSG: '（auマネ活プラン未連携）auマネ活プラン加入と条件達成でau PAY 残高に月々800円還元！詳細はこちら',
  JBANK_GA_MSG: '（auマネ活プラン未連携）auじぶん銀行口座をお持ちならauマネ活プラン加入と条件達成でau PAY 残高に月々800円還元！詳細はこちら',
  MSG_APPLIED: '（auマネ活プラン連携済）auマネ活プランの特典適用状況はこちら'
};
